import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import media from 'styled-media-query'
import PropTypes from 'prop-types'

import eventPic1 from '../images/events/hotblack-events1.jpg'
import eventPic2 from '../images/events/hotblack-events2.jpg'
import eventPic3 from '../images/events/hotblack-events3.jpg'
import eventPic4 from '../images/events/hotblack-events4.jpg'
import eventPic5 from '../images/events/hotblack-events5.jpg'

import eventPdf from '../images/events/hotblack-eventspace.pdf'

const SectionWrapper = styled.section`
  min-height: 100vh;
  & > div:first-of-type {
    display: flex;
    height: 100vh;
    border-bottom: none;
    position: relative;
  }
  & > div:last-of-type {
    display: flex;
    position: relative;
    height: 100vh;
    border-bottom: none;
  }
`

const FirstPageTitle = styled.div`
  position: absolute;
  bottom: 0;
  left: 5%;
  z-index: 3;
  h1 {
    color: var(--coffee-white);
    font-size: 4.5em;
    ${media.lessThan('medium')`
    font-size: 3em;
    `};
  }
`

const FirstPageImage = styled.figure`
  margin: 0;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
`
const LastPageImage = styled.figure`
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  z-index: 2;
  margin: 0;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
`
const LastPageCTA = styled.div`
      position: absolute;
      background: white;
      padding: 2em 3em 3em;
      margin-right: 4em;
      z-index: 3;
      top: 10%;
      left: 5%;
      ${media.lessThan('medium')`
      padding: 2em 2em 3em;
      `};
      h1 {
        font-size: 2em;
        margin-bottom: 1em;
        line-height: 140%;
      }

      p {
        font-size: 1.5em;
        line-height: 133%;
        ${media.lessThan('medium')`
        font-size: 1.25em;
        `};
      }
      line-height: 100%;
      a {
        color: var(--flirt-alert);
      }
    }
`
const Page = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'left right';
  border-bottom: 1px solid lightgrey;
  ${media.lessThan('medium')`
  grid-template-columns: 1fr;
  grid-template-areas: 'left''right';
  `};
`

const Detail = styled.div`
  grid-area: ${props => props.side || null};
  border-right: ${props =>
    props.side === 'left' ? '1px solid lightgrey' : null};
  padding: 2em 2em;
  h3 {
    font-size: 2em;
    margin: 0;
    ${media.lessThan('medium')`
    margin: 8px 0;
    `};
  }
  h6 {
    font-size: 2em;
    margin: 0;
  }
  ul {
    padding: 0;
  }
  p {
    font-size: 1.125em;
  }
  li {
    font-size: 1em;
    list-style: none;
    line-height: 150%;
  }
`

const Figure = styled.figure`
  grid-area: ${props => props.side || null};
  margin: 0;
  height: 100%;
  z-index: 3;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
`

const EventsPage = () => (
  <Layout page={'events'}>
    <SectionWrapper>
      <Page id="page1">
        <FirstPageImage>
          <img src={eventPic1} />
        </FirstPageImage>
        <FirstPageTitle>
          <h1>Events At HotBlack Coffee</h1>
        </FirstPageTitle>
      </Page>

      <Page id="page2">
        <Detail side="left">
          <h3>HotBlack Events</h3>
          <p>
            Located in the heart of Toronto’s trendy Fashion and Entertainment
            District, HotBlack balances the elements of your neighborhood coffee
            shop with a chic and unique event space, creating the perfect
            combination to make any event flawless—day or night.
          </p>
          <p>
            Eurocentric minimalist design, state of the art equipment and a full
            private bar make for the crowning touches on your next corporate
            function, birthday celebration, charity and fundraising, dinner
            party, product launch or the just-because-you-want-to event.
          </p>
          <p>
            We also have a private sunny back patio and you can take full
            advantage of this added licensed real estate for your spring or
            summer soirée. Or gather around our firepit on cooler nights to warm
            up and toast marshmallows. The interior skylights and high ceilings
            maintain that airy ambiance, providing lots of natural light for
            events all year round.
          </p>
          <p>
            With a stellar sound system and AV hook-ups, HotBlack is the perfect
            space for coffee tastings, meetings, product launches, media
            junkets, wine tastings, cocktail-receptions, networking events, live
            bands performances, private dance-parties with DJ hook-ups,
            interactive team building events and much more!
          </p>
        </Detail>
        <Figure side="right">
          <img src={eventPic2} />
        </Figure>
      </Page>

      <Page id="page3">
        <Figure side="left">
          <img src={eventPic3} />
        </Figure>
        <Detail side="right">
          <h3>Venue Capacities</h3>
          <p>
            HotBlack is ideally suited for groups of 10-60 guests and our events
            team will work with you personally to create a set-up to best suit
            your needs.
          </p>
          <ul>
            <li>Indoor Capacity — 40ish guests (cocktail-style)</li>
            <li>Patio Capacity – 30ish guests (cocktail-style)</li>
          </ul>
          <h3>HotBlack On-site Event Catering</h3>
          <p>
            We work with the finest to provide a best-in-class culinary
            experience for your attending guests. Beverages + Bar offerings are
            provided in house, or you can bring in your favourite libations
            using our BYO License.
          </p>
          <p>
            Pick your caterer, or work with those who know us best and are
            within walking delivery – Nota Bene, Soho House, Bar Hop, Peter Pan,
            Flock and Rodney’s Oyster Bar.
          </p>
        </Detail>
      </Page>
      <Page id="page4">
        <Detail side="left">
          <h6>Bonus Possibility:</h6>
          <p>
            Impress your guests and park a cool Food Truck of your choice in our
            private parking space with power hookups located adjacent to our
            back patio.
          </p>
          <h3>HotBlack Off-site Catering</h3>
          <p>You can be the office hero!</p>
          <p>
            Book our catering services for your next internal meeting and we
            will provide all coffee, tea, soft drinks, sweet + savoury pastries,
            snack + lunch items to keep your team happy and caffeinated until
            the day’s end.
          </p>
          <p>
            Or liven things up with our special catering alcohol license! We are
            able to supply alcohol off-site to cater any office event or wrap
            party in your space or a third-party space.
          </p>
        </Detail>
        <Figure side="right">
          <img src={eventPic4} />
        </Figure>
      </Page>

      <Page id="page5">
        <Detail side="left">
          <h3>
            Ready To Host?
            <br />
            Here Is All You Need To Know:
          </h3>
          <h3>The Space</h3>
          <p>
            760 square feet (460 inside, 300 patio) open to the public, all
            fully licenced
          </p>
          <h3>The Location</h3>
          <p>
            HotBlack is centrally located at 245 Queen Street West, just west of
            University Avenue, at the intersection of Queen Street West and St.
            Patrick Street.
          </p>
          <p>
            TTC Subway Stop - Osgoode Station, TTC Streetcar Stop - Queen St.
            West @ Simcoe St
          </p>
          <p>
            Parking: Street parking or Green P lot on McCaul Street, Valet
            parking also available
          </p>
        </Detail>
        <Detail side="right">
          <h3>Booking Availability</h3>
          <p>
            As a neighbourhood coffee shop, HotBlack is open for public
            enjoyment 7-days a week from 7AM-7PM. Exclusive daytime functions
            are a possibility with a supplementary charge.
          </p>
          <p>
            Non-exclusive bookings of 12 guests or less are bookable daily
            during regular hours.
          </p>
          <p>
            Evening events are bookable for exclusive buy-out daily from 7PM –
            2AM.
          </p>
          <h3>Event Pricing</h3>
          <p>
            All events will be invoiced directly for food + beverage catering,
            with a nominal room fee.
          </p>
          <p>
            In order to secure the booking, we simply require a deposit of 50%
            of the estimated spend and the completion of a contract, with the
            remaining balance charged to the card on file at the end of the
            event, unless otherwise arranged.
          </p>
          <p>
            We can also simply rent you the space – with a small charge to set
            up and clean up afterwards
          </p>
        </Detail>
      </Page>
      <Page id="page6">
        <Detail side="left">
          <h3>The HotBlack Team</h3>
          <p>
            With the support of our highly professional and experienced events
            team, all functions will be managed by HotBlack President + Partner,
            Jimson Bienenstock.
          </p>
          <p>
            Jimson has managed the highest profile events in Toronto for 10
            years – TIFF Gala opening and screening parties, Hollywood &amp;
            Netflix wrap parties, Juno Awards, Bollywood IIFA, PDAC and
            Microsoft conventions, major product launches, trunk shows, press
            junkets, SickKids fundraising events, the Provincial and National
            Canadian Sommelier Competitions, wine tastings, cocktail events,
            live auctions, engagement and wedding parties, closing dinners,
            dinner presentations, and countless private birthday parties &amp;
            dinners.
          </p>
          <p>
            A former Director of the Canadian Association of Professional
            Sommeliers, General Manager, Sommelier and Food &amp; Beverage
            Director of SohoHouse, George, Verity, Montecito and the Royal York
            Hotel, you can rest assured that Jimson’s management of your special
            event or high-profile party will be flawlessly executed attaining
            the highest standards of friendly and professional service.
          </p>
        </Detail>
        <Detail side="right">
          <h3>
            State Of The Art Facilities — Allows Delivery Of Consistent
            Excellence
          </h3>
          <ul>
            <li>
              Full alcohol licence for the interior &amp; private patio with byo
              and catering endorsements
            </li>
            <li>
              Display icewell ideally suited to food, oysters, seafood, beer,
              white wine, champagne, etc
            </li>
            <li>
              Fully accessible barrier-free unisex ground floor washroom, with
              second lower washroom
            </li>
            <li>
              Direct delivery access from laneway in the rear of the building,
              with ample storage space
            </li>
            <li>
              Private back entrance &amp; parking for discrete VIP entrance and
              departure via laneway
            </li>
            <li>
              Fully soundproofed, and perfectly suited to DJ &amp; live music
            </li>
            <li>
              Full glassware from Zwilling Predicat &amp; Sorrento lead barium
              free – flutes &amp; wine glasses
            </li>
            <li>
              Zip-lines on every wall allow for the easy hanging of personalised
              decoration
            </li>
            <li>Ample secure coat check</li>
          </ul>
        </Detail>
      </Page>
      <Page id="page7">
        <Detail side="left">
          <h3>Tech + Specs + Extras</h3>
          <ul>
            <li>
              AV system: 4 x Soundtube SM590i-II speakers, Shure wireless
              microphone, Sonos wireless inputs, XLR, 3.5mm &amp; RCA DJ &amp;
              PA inputs, video projector RCA &amp; series inputs
            </li>
            <li>
              underfloor programmable high capacity air conditioning &amp;
              heating
            </li>
            <li>
              high capacity Kold Draft cube ice &amp; Manitowoc crushed ice
              machine
            </li>
            <li>on tap unlimited filtered sparkling and still water</li>
            <li>high speed glass washing machine</li>
            <li>
              spare dedicated 30Amp 220V and 120V three phase and single phase
              electrical outlets
            </li>
            <li>recording high-definition security cameras 24/7</li>
            <li>qualified First Aid certified staff always on-site</li>
          </ul>
          <h3>Coffee Tech</h3>
          <ul>
            <li>Full remineralised reverse osmosis water </li>
            <li>4x Nuovo Simonelli Climapro grinders</li>
            <li>Mahlkonig EK43 grinder</li>
            <li>Fetco Twin filter coffee brewer</li>
            <li>3x Modbar Espresso units, </li>
            <li>70°C &amp; 90°C instant hot water</li>
            <li>Coffees are all Direct Trade sourced</li>
          </ul>
        </Detail>
        <Detail side="right">
          <h3>Interactive Activity &amp; Team-building Packages</h3>
          <p>
            Fun and informative experiential activities/classes/workshops run by
            our in house experts. Pricing on demand.
          </p>
          <ul>
            <li>
              <strong>Coffee Master Class</strong>- How to make espresso and the
              perfect cappuccinos &amp; lattes
            </li>
            <li>
              <strong>Coffee On The Road</strong>- How to make the perfect
              coffee at home or while traveling
            </li>
            <li>
              <strong>Tea Workshop</strong>- where teas comes from, their taste
              and preparation (white, green, etc)
            </li>
            <li>
              <strong>Mixology</strong>- How to make and prepare simple or more
              advanced alcoholic cocktails
            </li>
            <li>
              <strong>Wine, Beer And Spirits Workshops</strong>- Interactive
              alcohol training sessions:
              <ul>
                <li>
                  Beer 101 – origins, production &amp; interactive tastings
                  (local and/or international)
                </li>
                <li>Wine 101, white wine 101, red wine 101, Champagne 101</li>
                <li>
                  Best of Canadian wines – tasting &amp; how to find the best
                  (they are never in the LCBO!)
                </li>
                <li>Pairing wines with cheeses and other foods</li>
                <li>
                  Basic to advanced interactive general or specific alcohol
                  lessons and tastings
                </li>
                (from alcohols in general, to gins / rums / aperitifs /whiskies
                / bourbons / tequilas etc)
              </ul>
            </li>
            <li>
              Discover your own palate (what grapes and wine styles do you
              really like and how to recognise &amp; purchase)
            </li>
          </ul>
          <li>
            <strong>Poker</strong>– rules &amp; regulations, how to play, or
            just play with our professional chips &amp; cards
          </li>
        </Detail>
      </Page>
      <Page id="pageFinal">
        <LastPageImage>
          <img src={eventPic5} />
        </LastPageImage>
        <LastPageCTA>
          <h1>Ready to host an amazing event?</h1>
          <p>Contact us: info@hotblack-coffee.com</p>
          <p>
            or download our{' '}
            <a href={eventPdf} target="_blank">
              PDF
            </a>{' '}
            for more infomation
          </p>
        </LastPageCTA>
      </Page>
    </SectionWrapper>
  </Layout>
)

EventsPage.propTypes = {
  page: PropTypes.string.isRequired,
}

export default EventsPage
